<template>
  <div>
    <div>
      <!--      轮播图 开始-->
      <NSpin :show="false" size="large">
        <NCarousel autoplay style="height: 480px">
          <img class="carousel-img" src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/lunbo1.bfc1185d.png" />
          <img class="carousel-img" src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/lunbo2.f10215c2.png" />
          <!-- <img class="carousel-img" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/lun3.png" /> -->
        </NCarousel>
      </NSpin>
      <!--      轮播图 结束-->
      <!-- <img class="banner" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/banner.png" alt=""> -->
    </div>

    <div class="banner2">
      <div class="warps_nav ">
        <div class="downcontent">
          距报名截止 <span class="downnumber">33</span> 天 <span class=" downnumber">12</span> 时
        </div>
        <div>
          <router-link to="/cyds/signup">
            <el-button class="banner2_btn">大赛报名</el-button>
          </router-link>
          <el-button class="banner2_btn" disabled>工作登录</el-button>
          <el-button class="banner2_btn" disabled>管理入口</el-button>
          <!-- <a href="http://www.qingjuck.com/qjsite/img/logo.773f0ec0.png" download="123.png">下载图片</a> -->
        </div>
      </div>
    </div>
    <div class="ban_title">
      <div class="ban_title_text">
        <div class="ban_title_line"></div>
        <div class="ban_title_text_in">大赛动态</div>
        <div class="ban_title_line2"></div>
      </div>
      <div class="ban_title_text_en">NEWS CENTER</div>
    </div>
    <div class="warp">
      <el-row :gutter="40">
        <el-col class="cards" :span="6" v-for="(o, ) in newlist" :key="o.id">
          <el-card class="cards_in" style="background: #D4D4D4;" :body-style="{ padding: '0px' }">
            <img :src="o.imgs" class="image">
            <div style="padding: 14px;">
              <div class="card_title">
                {{o.title}}
              </div>
              <p class="card_text">
                {{o.text}}
              </p>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-button class="new_btn">查看更多</el-button>
    </div>
    <div class="warp" style="margin-bottom:42px;">
      <el-row :gutter="40">
        <el-col class="cards" :span="8">
          <div class="ambo">
            <img class="guide_img1" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/study.png" alt="">

            <div class="">
              <div class="guide_title">
                线上课程
              </div>
              <div class="guide_tip">
                潍坊创业大学全国比赛
              </div>
            </div>
          </div>
        </el-col>
        <el-col class="cards" :span="8">
          <div class="ambo">
            <img class="guide_img2" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/guide.png" alt="">

            <div class="">
              <div class="guide_title">
                操作说明
              </div>
              <div class="guide_tip">
                潍坊创业大学全国比赛
              </div>
            </div>
          </div>
        </el-col>
        <el-col class="cards" :span="8">
          <div class="ambo">
            <img class="guide_img3" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/male62.png" alt="">

            <div class="">

              <div class="guide_title">
                学生管理
              </div>
              <div class="guide_tip">
                潍坊创业大学全国比赛
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <img class="banner" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/banner3.png" alt="">
    </div>
    <div style="background-color: #EDEDED;">
      <div class="ban_title">
        <div class="ban_title_text">
          <div class="ban_title_line"></div>
          <div class="ban_title_text_in">大赛相关</div>
          <div class="ban_title_line2"></div>
        </div>
        <div class="ban_title_text_en">about match</div>
      </div>
      <div class="relevant">
        <div class="relevant_left">
          <div class="flex_star">
            <img class="relevant_img1" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/relevant1.png" alt="">
            <span class="relevant_title">大赛介绍</span>
          </div>
          <div class="relevant_content">
            大赛设立组织委员会（简称大赛组委会），由教育部和山东省人民政府主要负责同志担任主任，教育部和江西省分管领导担任副主任，教育部高等教育司主要负责同志担任秘书长，有关部门（单位）负责人作为成员，负责大赛的组织实施。
          </div>
          <div class="relevant_line"></div>
          <div class="flex_star">
            <img class="relevant_img2" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/relevant2.png" alt="">
            <span class="relevant_title">大赛组委会联系方式</span>
          </div>
          <div class="flex_star">
            <div class="relevant2_left">
              <div>
                <img class="relevant2_cydswxcode"
                  src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/cydswxcode.png" alt="">
              </div>
              <div class="relevant2_cydswxcode_title">大赛官方微信号</div>
            </div>
            <div class="relevant2_right">
              <div>地址：山东省潍坊市奎文区新华路3330号</div>
              <div>电话：010-1221254444</div>
              <div>邮编：261041</div>
              <div style="margin-top:24px;margin-bottom:12px;">大赛投诉：service@wfcydx.org.cn</div>
            </div>
          </div>
          <div class="relevant_line"></div>
          <div class="flex_star">
            <img class="relevant_img3" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/relevant3.png" alt="">
            <span class="relevant_title">奖项设置</span>
          </div>
          <div class="relevant_content3">
            高教主赛道：中国大陆参赛项目设金奖150个、银奖350个、铜奖1000个，中国港澳台地区参赛项目设金奖5个、银奖15个、铜奖另定，国际参赛项目设金奖50个、银奖100个、铜奖350个；设置最佳带动就业奖、最佳创意奖、最具商业价值奖、最具人气奖等若干单项奖；设置高校集体奖20个、省市优秀组织奖10个（与职教赛道合并计算）和优秀创新创业导师若干名。
          </div>
        </div>

        <div class="relevant_right">
          <div class="flex_star">
            <img class="relevant_img4" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/relevant4.png" alt="">
            <span class="relevant_title">大赛组委会联系方式</span>
          </div>
          <div class="relevant4_content">
            <div class="flex_star">
              <el-button class="relevant4_btn" type="primary">大赛通知</el-button>
              <el-button class="relevant4_btn" type="primary">评审规则</el-button>
            </div>
            <div class="flex_star" style="margin-top:15px;">
              <el-button class="relevant4_btn" type="primary">学生操作手册</el-button>
              <el-button class="relevant4_btn" type="primary">商业合作邀请函</el-button>
            </div>
            <div class="flex_star" style="margin-top:15px;">
              <el-button class="relevant4_btn" type="primary">大赛指南</el-button>
              <el-button class="relevant4_btn" type="primary">大赛评审手册</el-button>
            </div>
          </div>
          <div class="relevant_line" style="margin-top:40px;margin-left:80px"></div>
          <div class="flex_star">
            <img class="relevant_img5" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/relevant5.png" alt="">
            <span class="relevant_title">参赛类别</span>
          </div>
          <div class="relevant_content3" style="margin-left:103px">
            高教主赛道、青年红色筑梦之旅赛道：“互联网+”现代农业、“互联网+”制造业、“互联网+”信息技术服务、“互联网+”文化创意服务、“互联网+”社会服务
          </div>
          <div class="relevant_line" style="margin-top:40px;margin-left:80px"></div>
          <div class="flex_star">
            <img class="relevant_img6" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/relevant6.png" alt="">
            <span class="relevant_title">参赛组别</span>
          </div>
          <div class="relevant_content3" style="margin-left:103px">
            <div>
              高教主赛道：1. 本科生组：创意组、初创组、成长组
            </div>
            <div>
              <span style="opacity: 0;">高教主赛道：</span>2. 研究生组：创意组、初创组、成长组
            </div>
            <div>青年红色筑梦之旅赛道：公益组、创意组、创业组</div>
            <div>职教赛道：创意组、创业组</div>
          </div>
        </div>
      </div>
      <div class="ban_title">
        <div class="ban_title_text">
          <div class="ban_title_line"></div>
          <div class="ban_title_text_in">大赛视频</div>
          <div class="ban_title_line2"></div>
        </div>
        <div class="ban_title_text_en">VIDEO CENTER</div>
      </div>
      <div class="cyds_video">
        <div class="cemnets">
          <!-- <video src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/cydsv.mp4" controls></video> -->
        </div>
      </div>
    </div>
    <div class="ban_title">
      <div class="ban_title_text">
        <div class="ban_title_line"></div>
        <div class="ban_title_text_in">大赛回顾</div>
        <div class="ban_title_line2"></div>
      </div>
      <div class="ban_title_text_en">NEWS CENTER</div>
    </div>
    <div class="warp" style="margin-bottom:42px;">
      <div class="flex_space">
        <div class="lun_box">
          <el-carousel class="lun_box">
            <el-carousel-item class="lun_box">
              <img class="lun_img" src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/lunbo1.png" alt="">

              <div class="luntit">
                2020年“潍坊银行杯”创业大赛三等奖
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="flex_space" style=" flex-direction: column;height: 452px;">
          <div class="lun_right">
            <img src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/lunr1.png" alt="">
            <div class="lun_r_tit">
              第六届潍坊市大学生创新创业大赛圆满结束
            </div>
          </div>

          <div class="lun_right">
            <img src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/lunr2.png" alt="">
            <div class="lun_r_tit">
              第六届潍坊市大学生创新创业大赛圆满结束
            </div>
          </div>
        </div>
      </div>

      <div class="flex_space" style="flex-wrap: wrap;">
        <div class="review_box flex_star" v-for="item in reviewlist" :key='item.id'>
          <img class="review_img" :src="item.imgs" alt="">
          <div>
            <h3 class="review_title">潍坊市2021年乡村振兴创业大赛决赛暨颁奖</h3>
            <div class="review_time">2021-10-22 10:25</div>
            <div class="review_text">10月20日下午，由潍坊市2021年乡村振兴创业大赛组委会主办，市人社局、市财政局、市农业农村局承办的2021年乡村振兴创业大赛决赛</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {
  ref,
  nextTick,
  onMounted,
  onBeforeUnmount,
  computed,
  reactive,
  defineEmit,
  useContext,
  watch,
} from 'vue';
import Header from './components/Header.vue';
import SingleUploadFace from "@/components/single-upload/mainFace";
import { ElMessage } from 'element-plus'
import Login from './components/Login.vue';
import { useStore } from 'vuex';
import { getUserInfoApi, uploadRacePhotos } from '@/api/cyds';
const store = useStore();
const isLogin = computed(() => Boolean(store.state.cydstoken));
const reviewlist = ref([
  {
    id: 1,
    imgs: 'https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/review1.png',
  }, {
    id: 2,
    imgs: 'https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/review1.png',
  }, {
    id: 3,
    imgs: 'https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/review1.png',
  }, {
    id: 4,
    imgs: 'https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/review1.png',
  }, {
    id: 5,
    imgs: 'https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/review1.png',
  }, {
    id: 6,
    imgs: 'https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/review1.png',
  }
])
const newlist = ref([
  {
    id: 1,
    title: '第六届潍坊市奎文区大学生创新创业大赛成功举办',
    text: '9月17日，第六届潍坊市奎文区大学生创新创业大赛在潍坊市资本服务中心举办，本次比赛共吸引在奎文区注册的大学生企业项目代表、山东信息职业技术学院和潍坊医学院创新创意项目的大学生代表共34组选手参赛，大赛分为创业企业组和创新创意组，比赛采取参赛者现场演讲、播放视频、',
    imgs: 'https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/4bf283855c4e396db2c43419eaa87ac.jpg',
  }, {
    id: 2,
    title: '第六届潍坊市奎文区大学生创新创业大赛成功举办',
    text: '5月13日，潍坊市人民政府新闻办公室举办了潍坊市2021年乡村振兴创业大赛和第六届潍坊市大学生创新创业大赛新闻发布会，大赛正式启动。据悉，2021年乡村振兴创业大赛设置一、二、三等奖、优秀奖、创业好导师奖和优秀组织奖，最高奖金5万元。同时增加了潍坊市“十大返乡创业农民工”评选，评选出的优秀返乡创业农民工',
    imgs: 'https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/98463d2f3a04d92c8509e8a462f4379.jpg',

  }, {
    id: 3,
    title: ' “潍坊银行”杯第九届全市青年创新创业大赛决赛圆满落下帷幕 ',
    text: '12月28日下午，由潍坊市活力城市建设专项委员会办公室、中共潍坊市委宣传部、共青团潍坊市委、潍坊市财政局、潍坊市人力资源和社会保障局、潍坊银行联合主办的“潍坊银行”杯第九届全市青年创新创业大赛决赛在潍坊电视台演播大厅举行。',
    imgs: 'https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/881e4f57043682dfd8b2f668a405169.jpg',

  }, {
    id: 4,
    title: '潍坊市大学生创新创业大赛收官',
    text: '据了解，由潍坊市人力资源和社会保障局主办的2019潍坊市大学生创新创业大赛自3月份正式启动，在各县市区和驻潍院校设分赛区，全市1000余名大学生创业者报名参赛，经过分赛区选拔赛、创业训练营、市级半决赛和总决赛，30名选手脱颖而出。',
    imgs: 'https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/dcceb7f6320e1b0b2b65507cfa4eb80.jpg',

  },
])
</script>

<style>
.banner {
  width: 100%;
}
.banner2 {
  width: 100%;
  height: 112px;
  background: url('https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/banner(1).png');
}
.warps_nav {
  width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 112px;
}
.downcontent {
  font-size: 24px;
  color: #fff;
}
.downnumber {
  font-size: 48px;
  border-bottom: 2px solid #fff;
}
.banner2_btn {
  margin-right: 46px;
  color: #2b72f3;
  width: 162px;
  height: 53px;
  font-size: 24px;
  box-sizing: border-box;
}
.ban_title_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 408px;
  padding-top: 50px;
}
.ban_title {
  width: 500px;
  margin: 0 auto;
  height: 132px;
}
.ban_title_text {
  margin: 0 auto;
}
.ban_title_text_in {
  width: 137px;
  height: 31px;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
}
.ban_title_line {
  width: 108px;
  height: 3px;
  background: linear-gradient(90deg, #ffffff, #777777);
}
.ban_title_line2 {
  width: 108px;
  height: 3px;
  background: linear-gradient(90deg, #777777, #ffffff);
}
.ban_title_text_en {
  width: 141px;
  height: 12px;
  font-size: 18px;
  font-family: Bell MT;
  font-weight: 400;
  color: #a1a0a0;
  line-height: 24px;
  margin: 0 auto;
  text-align: center;
  margin-top: 14px;
}
.warp {
  width: 1340px;
  margin: 0 auto;
}
.cards .image {
  width: 297px;
  height: 147px;

  margin: 8px auto;
}
.card_title {
  width: 274px;
  height: 35px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card_text {
  width: 268px;
  height: 90px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #818181;
  line-height: 18px;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 10px;
}
.cards_in {
  background: #ebe9e9;
}
.new_btn {
  width: 152px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #a3a3a3;
  color: #8b8888;
  font-size: 14px;
  margin: 41px auto;
  display: block;
}
.ambo {
  display: flex;
  width: 100%;
  height: 132px;
  background-color: #2b72f3;
  color: #fff;
}
.guide_img1 {
  width: 72px;
  height: 63px;
  margin-top: 48px;
  margin-left: 30px;
  margin-right: 37px;
}
.guide_img2 {
  width: 66px;
  height: 74px;
  margin-top: 35px;
  margin-left: 42px;
  margin-right: 37px;
}
.guide_img3 {
  width: 89px;
  height: 77px;
  margin-top: 31px;
  margin-left: 32px;
  margin-right: 37px;
}
.guide_title {
  margin-top: 40px;
  height: 24px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
}
.guide_tip {
  height: 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  margin-top: 17px;
}
.relevant {
  width: 1161px;
  /* height: 751px; */
  box-sizing: border-box;
  padding: 31px 36px 31px 18px;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
}
.relevant_left {
  width: 580px;
  border-right: 1px solid #bcbcbc;
}
.relevant_right {
  width: 580px;
}
.relevant_title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #010101;
  line-height: 24px;
}
.relevant_img1 {
  width: 55px;
  height: 42px;
  margin-right: 20px;
  margin-left: 9px;
}
.flex_star {
  display: flex;
  align-items: center;
}
.relevant_content {
  width: 489px;
  height: 75px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4c4b4b;
  line-height: 20px;
  margin-left: 17px;
  margin-top: 25px;
  margin-bottom: 40px;
}
.relevant_line {
  width: 480px;
  height: 1px;
  background-color: #bcbcbc;
  margin-left: 25px;
  margin-bottom: 20px;
}
.relevant_img2 {
  width: 55px;
  height: 55px;
  margin-left: 10px;
  margin-right: 18px;
}
.relevant2_left {
  width: 120px;
  border-right: 1px solid #bcbcbc;
  margin-left: 25px;
  margin-top: 17px;
  margin-bottom: 40px;
}
.relevant2_cydswxcode {
  width: 79px;
  height: 80px;
  margin-left: 6px;
  margin-top: 14px;
}
.relevant2_cydswxcode_title {
  height: 12px;
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4c4b4b;
  line-height: 12px;
  margin-top: 20px;
  margin-bottom: 7px;
}
.relevant2_right {
  margin-left: 44px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4c4b4b;
  line-height: 24px;
}
.relevant_img3 {
  width: 71px;
  height: 59px;
  margin-right: 12px;
}
.relevant_content3 {
  width: 477px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4c4b4b;
  line-height: 24px;
  margin-left: 25px;
  margin-top: 23px;
  margin-bottom: 11px;
}
.relevant_img4 {
  margin-left: 50px;
  margin-right: 18px;
  width: 48px;
  height: 55px;
}
.relevant4_content {
  margin-left: 125px;
  margin-top: 33px;
}
.relevant4_btn {
  width: 140px;
  height: 34px;
  margin-right: 20px;
  font-size: 14px;
}
.relevant_img5 {
  width: 56px;
  height: 44px;
  margin-left: 60px;
  margin-right: 14px;
}
.relevant_img6 {
  width: 62px;
  height: 56px;
  margin-left: 60px;
  margin-right: 8px;
}
.cyds_video {
  width: 100%;
  background-image: url('https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/cyds_video.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.cemnets {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
}
.lun_box {
  width: 923px;
  height: 452px;
}
.lun_img {
  width: 100%;
  height: 100%;
}
.luntit {
  position: absolute;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
  width: 100%;
  color: #fff;
  padding-left: 56px;
  box-sizing: border-box;
  font-size: 14px;
}
.flex_space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lun_right {
  position: relative;
  width: 358px;
  height: 217px;
}
.lun_r_tit {
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: #ffffff;
  line-height: 36px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 356px;
  height: 36px;
  background: #000000;
  box-sizing: border-box;
  padding-left: 10px;
}
.review_box {
  width: 670px;
  box-sizing: border-box;
  margin-top: 40px;
}
.review_img {
  width: 159px;
  height: 133px;
  margin-right: 32px;
}
.review_title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  overflow: hidden;
}
.review_time {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #444242;
  line-height: 42px;
  height: 42px;
}
.review_text {
  width: 430px;
  height: 39px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #747474;
  line-height: 22px;
  overflow: hidden;
}
</style>